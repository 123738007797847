import fortnoxModalTemplate from "../partial/invoice/fortnoxModal.html";

angular
  .module("kitstajm")
  .factory("Auth", function ($resource) {
    return $resource(
      "api/login",
      {},
      {
        get: { method: "GET" },
        check: { method: "POST" },
        changePwd: { method: "PUT" },
      }
    );
  })
  .factory("Auth2", [
    "$resource",
    function ($resource) {
      return $resource(
        "api/logout",
        {},
        {
          check: { method: "GET" },
        }
      );
    },
  ])
  .factory("OauthUtil", [
    "Modal", "FortNoxAuth", "$location",
    function (Modal, FortNoxAuth, $location) {

      let oauthLoginHandler = function (error) {

        let fortnoxModalDefaults = {
          template: fortnoxModalTemplate,
          windowClass: "error-modal-window",
        };

        let fortnoxModalOptions = {
          actionButtonText: "Ok",
          closeButtonText: "Avbryt",
          headerText: "Det krävs en ny inloggning till Fortnox",
        };

        fortnoxModalOptions.bodyText = `${error.data}`;

        let fortnoxModal = Modal.showModal(
          fortnoxModalDefaults,
          fortnoxModalOptions
        );

        fortnoxModal.then(
          function (success) {
            const newWindow = window.open(success, "_blank");
            function handleMessage(event) {
              if (event.origin == window.location.origin) {
                /*console.log(
                  `Meddelande från nytt (${
                    window.location.origin
                  }) fönster: ${JSON.stringify(event.data)}`
                );*/
                if (event.data === "close") {
                  newWindow.close();
                  // Ta bort event listenern när den inte längre behövs
                  window.removeEventListener("message", handleMessage);
                }
              }
            }
            window.removeEventListener("message", handleMessage);
            window.addEventListener("message", handleMessage);
          },
          function (close) {
          }
        );
      }

      let fullUrl = function () {
        let fullUrl =
          $location.protocol() +
          "://" +
          $location.host() +
          ($location.port() ? ":" + $location.port() : "") +
          $location.path();

        return fullUrl;
      };

      let getAccessToken = function () {
        let accessCode = $location.search().code;
        let state = $location.search().state;
        if (accessCode) {
          console.log("URL parameter accessKod:", accessCode);
          console.log("URL parameter state:", state);
          FortNoxAuth.save(
            {},
            {
              access_code: accessCode,
              redirect_uri: fullUrl(),
              state: state,
            },
            function (success) {
              console.log("FortNoxAuth success", success);
              // Remove all query parameters from the URL
              const url = new URL(window.location);
              url.search = ""; // Clear all query parameters
              history.replaceState(null, "", url.toString());
              window.opener.postMessage("close", window.location.origin);
            },
            function (error) {
              console.log("FortNoxAuth error", error);
            }
          );
        } else {
          console.log("No URL parameter accessKod");
        }
      }

      return {
        oauthLoginHandler: oauthLoginHandler,
        getAccessToken: getAccessToken,
        fullUrl: fullUrl,
      };
    },
  ]);
